.navbar-nav-scroll {
    height: auto;
}

.navbar-toggler {
    color: white;
    font-size: 28px;
}


.heading:hover {
    transition-duration: 0.8s !important;
    font-size: 22px !important;
    color: rgb(173, 11, 11) !important;
}