.blackBg{
    background-color: rgba(0,0,0,0.5);
}
ul {
    list-style: none;
  }
  
  ul li:before {
    content: '✓  ';
  }
.bgimage{
    background-position: center;
    background-repeat: no-repeat;
    transition-duration: 2s;
    background-size: cover;
}
.shadow{
    text-shadow: 2px 1px 8px black,2px 1px 15px black,2px 1px 8px black ;
}
.Lborder{
    border-left: 2px solid gray;
}

.heading{
    transition-duration: 0.3s;
    color:white;
}
.heading:hover{
    text-shadow:3px 5px 3px black,5px 14px 19px white;
}
.Bluetxt{
    color: blue;
    font-size: 44px;
    font-weight: 700;
}
.fntAwsm{
   font-size: 30px;
}
.MainAct{
    font-size: 20px;
}