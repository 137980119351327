.blackBg {
  background-color: rgba(0, 0, 0, 0.5);
}

ul {
  list-style: none;
}

.heading {
  transition-duration: 0.3s;
  color: white;
}

.heading:hover {
  text-shadow: 3px 5px 3px black, 5px 14px 19px white;
}

.heading{
  transition-duration: 0.3s;
  color:white;
}

ul li:before {
  content: '✓  ';
}

.bgImg {
  background-size: cover;
  background-position: bottom
}


.MainAct {
  font-size: 17px;
}