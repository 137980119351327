.blackBg{
    background-color: rgba(0,0,0,0.5);
}
*{
    text-decoration: none;
}
.textbox{

    height: 150px;
}

.heading:hover {
    text-shadow: 3px 5px 3px black, 5px 14px 19px white;
  }
  
  .heading{
    transition-duration: 0.3s;
    color:white;
  }