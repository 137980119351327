.blackBg {
    background-color: rgba(0, 0, 0, 0.5);
}

.fontSize {
    font-size: 2rem;
}

.bgImg {
    background-size: cover;
    background-position: bottom
}

.heading:hover {
    text-shadow: 3px 5px 3px black, 5px 14px 19px white;
}

.heading {
    transition-duration: 0.3s;
    color: white;
}

.heading {
    transition-duration: 0.3s;
    color: white;
}

.myCard {
    height: 300px !important;
}

@media screen and (min-width: 768px) {
    .myCard {
        height: 150px !important;
    }
}

@media screen and (min-width: 1024px) {
    .myCard {
        height: 290px !important;
    }
}