.fontSize {
  font-size: 2rem;
}

.blackBg {
  background-color: rgba(0, 0, 0, 0.5);
}

.bgImg {
  background-position: center;
  background-repeat: no-repeat;
  transition-duration: 2s;
  background-size: cover;
}

.heading:hover {
  text-shadow: 3px 5px 3px black, 5px 14px 19px white;
}

.heading{
  transition-duration: 0.3s;
  color:white;
}